import React from 'react'

const RightChevron = ({ className }) => {
  return (
    <svg
      className={`button__svg stroke-current inline-block${className !==
        undefined && ' ' + className}`}
      width="9"
      height="14"
      viewBox="0 0 9 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 13L2 6.59 8 1" strokeWidth="2" fill="none" />
    </svg>
  )
}

export default RightChevron
