import React from 'react'
import Img from 'gatsby-image'
import Slider from 'react-slick'

import RightChevron from '../SVG/RightChevron'
import LeftChevron from '../SVG/LeftChevron'

const NextArrow = ({ onClick, className }) => {
  return (
    <div className={className} onClick={onClick}>
      <RightChevron />
    </div>
  )
}

const PrevArrow = ({ onClick, className }) => {
  return (
    <div className={className} onClick={onClick}>
      <LeftChevron />
    </div>
  )
}

class GallerySlider extends React.Component {
  render() {
    const { data, className } = this.props
    var settingsThree = {
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: <NextArrow className="w-8 h-8 bg-white" />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 990,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    }
    var settingsTwo = {
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      nextArrow: <NextArrow className="w-8 h-8 bg-white" />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    }
    var settingsOne = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <NextArrow className="w-8 h-8 bg-white" />,
      prevArrow: <PrevArrow />,
    }

    let settings
    if (data.length > 3) {
      settings = settingsThree
    } else if (data.length > 2) {
      settings = settingsTwo
    } else {
      settings = settingsOne
    }

    return (
      <>
        {data.length > 1 ? (
          <>
            <h4 className="hidden mb-8 text-2xl font-semibold leading-tight lg:block rte">
              Gallery
          </h4>
            <Slider
              {...settings}
              className={`break-out-right -ml-10 relative ${className}`}
            >
              {data.map((item, index) => {
                return (
                  <div key={index} className="block sm:pl-10 focus:outline-none">
                    {item.gallery_image.url !== null && (
                      <Img
                        className="block"
                        fluid={item.gallery_image.localFile.childImageSharp.fluid}
                        alt={item.gallery_image.alt}
                      />
                    )}
                  </div>
                )
              })}
            </Slider>
          </>
        ) : (
            <>
              {
                data[0].gallery_image.url !== null && (
                  <Img
                    className="block -mx-5 lg:mx-0"
                    fluid={data[0].gallery_image.localFile.childImageSharp.fluid}
                    alt={data[0].gallery_image.alt}
                  />
                )
              }
            </>

          )}
      </>
    )
  }
}

export default GallerySlider
