import React from 'react'
import { Link } from 'react-scroll'
import Img from 'gatsby-image'

import DownChevron from '../SVG/DownChevron'

const Hero = ({ data }) => {
  return (
    <div className="bg-blue text-white">
      <div className="wrapper wrapper--y">
        {data.heading && <h1 className="mb-16 heading-2xl">{data.heading}</h1>}
        <div className="lg:flex">
          <div className="lg:w-5/12">
            <div className="md:w-3/4 lg:w-2/3 flex flex-col h-full justify-between">
              <div className="mb-8 font-semibold">
                {data.lead.html && (
                  <div
                    className="rte text-xl"
                    dangerouslySetInnerHTML={{ __html: data.lead.html }}
                  />
                )}
              </div>
              <div className="mb-32 font-bold">
                {data.call_to_action_heading && (
                  <h4 className="mb-8">{data.call_to_action_heading}</h4>
                )}
                {data.call_to_action_button_text && (
                  <Link
                    className="button button--blue button--down px-6 border inline-block"
                    to="/careers"
                    smooth={true}
                  >
                    <div className="button__inner font-semibold">
                      {data.call_to_action_button_text}
                      <DownChevron className="ml-8" />
                    </div>
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="lg:w-7/12">
            <div className="break-out-right break-out-left xl:img-break-out md:ml-0 h-full">
              <Img
                className="h-full"
                fluid={data.hero.localFile.childImageSharp.fluid}
                alt={data.hero.alt}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
