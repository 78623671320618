import React from 'react'

import GallerySlider from '../GallerySlider'

const Careers = ({ data }) => {
  return (
    <div className="bg-blue text-white">
      <div className="wrapper pt-20 lg:pt-42">
        <div className="lg:flex mb-12 lg:mb-38">
          <div className="lg:w-1/2">
            {data.careers_heading && (
              <>
                <h4
                  className="lg:w-1/2 block heading-xl mb-8"
                  dangerouslySetInnerHTML={{
                    __html: data.careers_heading,
                  }}
                />
              </>
            )}
          </div>
          {data.careers_body.html && (
            <div
              className="rte lg:w-1/2 font-semibold"
              dangerouslySetInnerHTML={{ __html: data.careers_body.html }}
            />
          )}
        </div>

        <GallerySlider data={data.careers_gallery} />
      </div>
    </div>
  )
}

export default Careers
