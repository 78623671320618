import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Element } from 'react-scroll'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Hero from '../components/About/Hero'
import Accreditations from '../components/About/Accreditations'
import Positions from '../components/About/Positions'
import Team from '../components/About/Team'
import Careers from '../components/About/Careers'

const AboutPage = ({ data, location }) => {
  const page = data.prismicAbout.data
  return (
    <Layout location={location}>
      <SEO
        title={page.meta_title && page.meta_title}
        location={location}
        description={page.meta_description && page.meta_description}
      />
      <Hero data={page} />
      <Accreditations data={page} />
      <Team data={page} />

      <Element name="careers">
        <Careers data={page} />
        <Positions data={page} />
      </Element>
    </Layout>
  )
}

AboutPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const pageAboutQuery = graphql`
  query {
    prismicAbout {
      data {
        heading
        lead {
          html
          text
        }
        hero {
          url
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 1800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        call_to_action_heading
        call_to_action_button_text
        accreditations_heading
        accreditations_body {
          html
          text
        }
        team_heading
        team_members {
          team_member_image {
            url
            alt
            localFile {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          team_member_name
          team_member_role
          team_member_email
        }
        careers_heading
        careers_body {
          html
          text
        }
        careers_gallery {
          gallery_image {
            url
            alt
            localFile {
              childImageSharp {
                fluid(maxWidth: 1300, maxHeight: 855) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        positions_heading
        positions {
          position_heading
          position_link {
            url
          }
        }
        positions_message {
          html
          text
        }
        meta_title
        meta_description
      }
    }
  }
`

export default AboutPage
