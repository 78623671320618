import React from 'react'
import RightChevron from '../SVG/RightChevron'

const Positions = ({ data }) => {
  return (
    <div className="bg-blue text-white font-semibold">
      <div className="wrapper pt-40 pb-20">
        {data.positions_heading && (
          <div className="lg:flex">
            <div className="lg:w-1/2">
              <h4
                className="w-2/3 heading-xl mb-8 lg:mb-20"
                dangerouslySetInnerHTML={{
                  __html: data.positions_heading,
                }}
              />
            </div>
            {data.positions.length === 0 &&
              (data.positions_message && (
                <div
                  className="rte lg:w-1/2 font-semibold"
                  dangerouslySetInnerHTML={{
                    __html: data.positions_message.html,
                  }}
                />
              ))}
          </div>
        )}
        {data.positions.length > 0 && (
          <ul>
            {data.positions.map((item, index) => {
              return (
                <li
                  className={`border-t flex items-center justify-between -ml-2 py-5${
                    index >= data.positions.length - 1 ? ' border-b' : ''
                  }`}
                  key={index}
                >
                  {item.position_heading && (
                    <a
                      className="pl-2 cursor-pointer button-group"
                      href={item.position_link.url}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <h5 className="text-xl lg:text-2xl">
                        {item.position_heading}
                      </h5>
                    </a>
                  )}
                  {item.position_link.url && (
                    <a
                      className="mx-2 button button--blue border"
                      href={item.position_link.url}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <div className="button__inner">
                        <span className="hidden lg:block">View Listing</span>
                        <RightChevron className="lg:ml-8" />
                      </div>
                    </a>
                  )}
                </li>
              )
            })}
          </ul>
        )}
      </div>
    </div>
  )
}

export default Positions
