import React from 'react'
import Img from 'gatsby-image'

const Team = ({ data }) => {
  return (
    <div className="bg-white lg:bg-blue">
      <div className="pt-28 pb-12 wrapper wrapper-spacing bg-white bg-after-white bg-after-white--left">
        {data.team_heading && (
          <h4 className="text-2xl mb-15 font-semibold">{data.team_heading}</h4>
        )}
        <div className="lg:w-10/12 flex flex-wrap -ml-15">
          {data.team_members.map((item, index) => {
            return (
              <div className="w-full md:w-1/2 lg:w-1/3 pl-15 pb-12" key={index}>
                {item.team_member_image.url && (
                  <Img
                    className="block mb-5"
                    fluid={
                      item.team_member_image.localFile.childImageSharp.fluid
                    }
                    alt={item.team_member_image.alt}
                  />
                )}
                <div className="leading-tight mb-2">
                  {item.team_member_name && (
                    <h3 className="font-semibold">{item.team_member_name}</h3>
                  )}
                  {item.team_member_role && <h4>{item.team_member_role}</h4>}
                </div>
                {item.team_member_email && (
                  <a className="link" href={'mailto:' + item.team_member_email}>
                    {item.team_member_email}
                  </a>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Team
