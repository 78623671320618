import React from 'react'

const Accreditations = ({ data }) => {
  return (
    <div className="bg-blue text-white">
      <div className="wrapper pt-20 lg:pt-28">
        <div className="lg:flex pb-16 lg:pb-24">
          <div className="lg:w-5/12 heading-xl mb-8">
            {data.accreditations_heading && (
              <h4
                className="lg:w-1/2 block heading-xl mb-8"
                dangerouslySetInnerHTML={{
                  __html: data.accreditations_heading,
                }}
              />
            )}
          </div>
          <div
            className="rte lg:w-7/12 md:two-column"
            dangerouslySetInnerHTML={{
              __html: data.accreditations_body.html,
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Accreditations
